import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from 'react-router-dom';

import Home from './Home';
import About from './About';
import Services from './Services'
import Contact from './Contact';

import Privacy from './Privacy';
import Support from './Support';
import Terms from './Terms';

import Blogs from './Blogs';
import Blog from './Blog';

import CaseStudies from './CaseStudies';
import CaseStudy from './CaseStudy';

function Header() {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/support' element={<Support />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/case-studies' element={<CaseStudies />} />
          <Route path='/case-study/:slug' element={<CaseStudy />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog/:slug' element={<Blog />} />
        </Routes>
      </Router>
    </>
  );
}

export default Header;