import React from "react";
import { Helmet } from 'react-helmet';

function Privacy() {
  return (
    <>
      <Helmet>
        <title>W3Nest | Privacy Policy</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>Privacy Policy</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Privacy Policy</li>
            </ol>
          </div>

        </div>
      </section>

      <section class="inner-page">
        <div class="container">
          <p>
            <b>Privacy Policy:</b><br />
            We do not store, rent, sell or share any emails,phone numbers or any other personal information collected on any of our websites with third parties for any purposes.<br /><br />

            <b>Changes to Our Privacy Policy:</b><br />
            As our organization and services evolve, this Privacy Policy may be revised to reflect those changes, where applicable. We therefore reserve the right to amend this Privacy Policy at any time, for any reason. Notice of such amendments will be posted whenever needed. Users are encouraged to check the website frequently to review the current Privacy Policy in effect.<br /><br />

            <b>Disclaimer:</b><br />
            All trademarks shown in our softwares/websites & websites are for reference purposes only, they are the property of their respective owners.<br /><br />

            Although the information found on our websites is believed to be reliable, no warranty, expressed or implied, is made regarding the accuracy, adequacy, completeness, legality, reliability, or usefulness of any information, either isolated or in the aggregate. The information is provided "as is". All warranties of any kind, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, freedom from contamination, and non-infringement of proprietary rights, are disclaimed. Changes may be periodically made to the information; these changes may or may not be incorporated into our apps. If you find any errors or omissions, we encourage you to report them by e-mail to: <a href="mailto:info@w3nest.com" rel="noreferrer" target="_blank">W3Nest Support</a>.
          </p>
        </div>
      </section>
    </>
  );
}

export default Privacy;