import React from "react";

function Footer() {
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-contact">
                <p>
                  <h4>India</h4>
                  New No. 66/Old No. 56, SPS First Street,  <br />
                  Chennai 600 014. TN. India.<br />
                  <strong>Email:</strong> <a href="mailto:info@w3nest.com" rel="noreferrer" target="_blank">info@w3nest.com</a><br /><br />
                </p>
                <p>
                  <h4>USA</h4>
                  1309 Coffeen Avenue STE 1200<br />Sheridan, WY 82801. United States.<br />
                  <strong>Email:</strong> <a href="mailto:info@appwings.com" rel="noreferrer" target="_blank">info@appwings.com</a><br />
                </p>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>W3Nest</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/about">About us</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/services">Services</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/case-studies">Case Studies</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/contact">Contact</a></li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/blogs">Blogs</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/support">Support</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/privacy">Privacy Policy</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a class="nav-link scrollto" href="/terms">Terms & Conditions</a></li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Stay connected with us:</p>
                <div class="social-links mt-3">
                  <a href="https://twitter.com/w3nest/" rel="noreferrer" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                  <a href="https://www.facebook.com/w3nestcom" rel="noreferrer" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                  <a href="https://www.instagram.com/w3nest/" rel="noreferrer" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="container py-4">
          <div class="copyright">
            &copy; 2022 <strong><span>W3Nest.com</span></strong>. All Rights Reserved.
          </div>
          <div class="credits">
            Powered by <a href="https://www.w3nest.com/"><strong><span>W3Nest.com</span></strong></a>.
          </div>
        </div>

        <div class="container py-4">
          <div class="credits text-left"><b>Partners & Channels:</b> <span><a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://appwings.com">App Wings LLC</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://anandtechmedia.com">Anand Tech Media</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://pixoho.com">Pixoho</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://namkural.com">Nam Kural</a> | <a class="text-decoration-none" rel="noreferrer" target="_blank" href="https://cineminnal.com">Cine Minnal</a></span>
          </div>
          <div class="credits">
          
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;