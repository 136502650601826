import React from "react";
import { Helmet } from 'react-helmet';

function Services() {
  return (
    <>
      <Helmet>
        <title>W3Nest | Services</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>Services</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Services</li>
            </ol>
          </div>

        </div>
      </section>
      <section id="section"class="section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Web & mobile Development</h2>
            <h3>Check our <span>Services</span></h3>
            <h5>Here are our key services</h5>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-envelope"></i></div>
                <h4>Mobile Development</h4>
                <p>Mobile App Development & Support/Maintenance Services </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
              <div class="icon-box">
                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                <h4>Web Development</h4>
                <p>Website, API Develpment & Support/Maintenance Services</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-news"></i></div>
                <h4>Content & SEO Services</h4>
                <p>We provide content writing (English & Tamil) & SEO Services.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="skills" class="skills">
        <div class="container" data-aos="fade-up">

          <div class="row skills-content">

            <div class="col-lg-6">

              <div class="progress">
                <span class="skill">HTML <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">CSS <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">JavaScript <i class="val">75%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

            </div>

            <div class="col-lg-6">

              <div class="progress">
                <span class="skill">PHP <i class="val">80%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">WordPress/CMS <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <div class="progress">
                <span class="skill">Photoshop <i class="val">55%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </section> */}
    </>
  );
}

export default Services;