import React from "react";
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <>
      <Helmet>
        <title>W3Nest | Contact Us</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>Contact Us</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Contact Us</li>
            </ol>
          </div>

        </div>
      </section>
      <section id="sectioun"class="secttion-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Contact</h2>
            <h3><span>Contact Us</span></h3>
            <p>Reach us to get the best services.</p>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-6">
              <div class="info-box mb-4">
                {/* <i class="bx bx-map"></i> */}
                <h3>India</h3>
                <p><b>W3Nest:</b><br />New No. 66/Old No. 56, SPS First Street, Chennai 600 014, TN, India.</p>
                <p>Email: <a href='mailto:info@w3nest.com' target='_blank' rel='noopener noreferrer'>info@w3nest.com</a></p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="info-box mb-4">
                {/* <i class="bx bx-map"></i> */}
                <h3>USA</h3>
                <p><b>App Wings LLC:</b><br />1309 Coffeen Avenue STE 1200, Sheridan, WY 82801, United States.</p>
                <p>Email: <a href='mailto:info@appwings.com' target='_blank' rel='noopener noreferrer'>info@appwings.com</a></p>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  );
}

export default Contact;