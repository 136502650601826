import React from "react";
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
      <Helmet>
        <title>W3Nest | About</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>About Us</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>About Us</li>
            </ol>
          </div>

        </div>
      </section>
      <section id="about" class="about section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>About</h2>
            <h3>Find Out More <span>About Us</span></h3>
            <p>W3Nest is a one-stop-shop for all the web related services such as web design/development, domain name registraion & hosting services etc..</p>
          </div>

          <div class="row">
            <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <img src="assets/img/about.jpg" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
              <h3>We take care of your online presence.</h3>
              <p class="fst-italic">
                We are a one-stop-shop for all your website needs including, web design, development, domain name, hosting, emails and much more.
              </p>
              <ul>
                <li>
                  <i class="bx bx-store-alt"></i>
                  <div>
                    <h5>Web Solutions</h5>
                    <p>We offer web solutions such as Website design, development.</p>
                  </div>
                </li>
                <li>
                  <i class="bx bx-store-alt"></i>
                  <div>
                    <h5>Online Store</h5>
                    <p>We sell top quality products through our multiple eCommerce stores.</p>
                  </div>
                </li>
                <li>
                  <i class="bx bx-images"></i>
                  <div>
                    <h5>Infra Structure</h5>
                    <p>We also help you with setting up and maintaining your web infrastructure.</p>
                  </div>
                </li>
              </ul>

            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default About;