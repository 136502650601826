import React from "react";
import { Helmet } from 'react-helmet';
import blogsData from "../data/blogs.json";
import { Link } from "react-router-dom";

function Blogs() {
  
  return (
    <>
      <Helmet>
        <title>W3Nest | Blogs</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>Blogs</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Blogs</li>
            </ol>
          </div>

        </div>
      </section>
      <section id="section"class="section-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up">

          <div class="section-title">
            <h2>Blogs</h2>
            <h3>Check our <span>Blogs</span></h3>
            <p>Here are our list of blogs.</p>
          </div>

          <div class="row">
          {blogsData && blogsData.map(({id,ti,md,iu,dt,sl}) => (
              <div class="col-lg-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div class="member">
                  <div class="member-img"> <img src={`./assets/img/blogs/${iu}`} alt={ti} class="img-fluid" /></div>
                  <div class="member-info"><Link to={`/blog/${sl}`}><h6>{ti}</h6></Link></div>

          
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>
    </>
  );
}

export default Blogs;