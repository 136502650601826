import React from "react";
import { Helmet } from 'react-helmet';

function Home() {
  return (
      <>
      <Helmet>
        <title>W3Nest | Website and Mobile App Development</title>
      </Helmet>
      <section id="hero" class="d-flex align-items-center">
        <div class="container" data-aos="zoom-out" data-aos-delay="100">
          <h1>Welcome to <span>W3Nest.com</span></h1>
          <h2>We are an eCommerce company selling both physical & digital products. We do web design & development have team of talented web designers & developers</h2>
          <div class="d-flex">
            <a href="#about" class="btn-get-started scrollto">Get Started</a>
            <a href="https://www.youtube.com/watch?v=TIA42gFDHG0" class="glightbox btn-watch-video"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>
          </div>
        </div>
      </section>

       <section id="featured-services" class="featured-services">
          <div class="container" data-aos="fade-up">

            <div class="row">
              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                  <div class="icon"><i class="bx bxl-dribbble"></i></div>
                  <h4 class="title">Web Development</h4>
                  <p class="description">We deal with web development mainly related with Open Source technologies.</p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                  <div class="icon"><i class="bx bx-file"></i></div>
                  <h4 class="title">eCommerce</h4>
                  <p class="description">We sell high quality physical & digital products.</p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                  <div class="icon"><i class="bx bx-tachometer"></i></div>
                  <h4 class="title">Domain Name & Web Hosting</h4>
                  <p class="description">We offer Domain Name registration service and also provide web hosting services including cloud hosting.</p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                  <div class="icon"><i class="bx bx-world"></i></div>
                  <h4 class="title">Microoft 365</h4>
                  <p class="description">Look like a world-class business, even if you operate out of your garage, with professional email and Office apps that help you save time and be more productive.</p>
                </div>
              </div>

            </div>

          </div>
        </section>
      </>
      );
}

      export default Home;