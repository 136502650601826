import React from "react";
import { Helmet } from 'react-helmet';

function Support() {
  return (
    <>
      <Helmet>
        <title>W3Nest | Support</title>
      </Helmet>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2>W3Nest Support</h2>
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>W3Nest Support</li>
            </ol>
          </div>

        </div>
      </section>
      <section id="section"class="section-bg">
        <div class='container'>
            <div class='section-title'>
                <h2>Email Support</h2>
                <p>
                    Please contact us for any support via <a href='mailto:info@w3nest.com' target='_blank' rel='noopener noreferrer'>info@w3nest.com</a>.
                </p><br />
            </div>
        </div>
        </section>
    </>
  );
}

export default Support;